/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {getParentRoute} from '../../../../../app/servics/CommonFunctions';
import { FormattedMessage, injectIntl } from "react-intl";


 function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
 
  let redirectUrl = getParentRoute(); 


  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
          <FormattedMessage id="PROFILE.HI" />,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.firstName} {user.lastName}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user.firstName.charAt(0)}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.firstName.charAt(0)}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstName} {user.lastName}
              </div>
              
            </div>
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to={redirectUrl+"user-profile"} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                <FormattedMessage id="PROFILE.PROFILE" />
                  
                </div>
                <div className="text-muted">
                <FormattedMessage id="PROFILE.SETTINGAMDMORE" />
                  
                </div>
              </div>
            </div>
          </Link>


          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to={redirectUrl+"logout"}
              className="btn btn-light-primary font-weight-bold"
            >
              <FormattedMessage id="PROFILE.SIGNOUT" />
              
            </Link>
            
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default injectIntl(UserProfileDropdown);