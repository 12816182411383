import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

function PositionedModal(props) {
  const [allInterpreterMailModal, setAllInterpreterMailModal] = useState(false);
  useEffect(() => {}, [props]);

  const onHideHandle = () => {
    setAllInterpreterMailModal(false);
  };

  return (
    <>
      <Modal
        centered={props.center}
        size="lg"
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          {props.CancelText ? (
            <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
              {props.CancelText ? props.CancelText : <FormattedMessage id="CANCEL" />}
            </button>
          ) : null}
          {props.interpreterMail ? (
            <button
              type="button"
              // onClick={props.onInterpreterMail}
              onClick={(e) => setAllInterpreterMailModal(true)}
              className="btn btn-warning"
            >
              {props.interpreterMailText ? (
                props.interpreterMailText
              ) : (
                <FormattedMessage id="INTERPRETER_MAIL" />
              )}
            </button>
          ) : null}

          {props.deletetext ? (
            <button
              type="button"
              onClick={props.deletebtn}
              className={props.deleteClass ? props.deleteClass : 'btn btn-light btn-elevate'}
            >
              {props.deletetext ? props.deletetext : <FormattedMessage id="DELETE" />}
            </button>
          ) : null}
          <> </>
          {props.updateText ? (
            <button type="submit" onClick={props.onSubmit} className="btn btn-primary btn-elevate">
              {props.updateText ? props.updateText : <FormattedMessage id="SAVE" />}
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>

      <Modal
        centered={true}
        size="md"
        show={allInterpreterMailModal}
        // onHide={onHideHandle}
        onHide={(e) => setAllInterpreterMailModal(false)}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="MAIL_INTERPRETER_TITLE" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage id="MAIL_INTERPRETER_BODY" />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={(e) => setAllInterpreterMailModal(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="CANCEL" />
          </button>

          {props.interpreterMail ? (
            <button
              type="button"
              // onClick={props.onInterpreterMail}
              onClick={props.onInterpreterMail}
              className="btn btn-light btn-elevate"
            >
              {props.interpreterMailText ? (
                props.interpreterMailText
              ) : (
                <FormattedMessage id="YES" />
              )}
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default injectIntl(PositionedModal);
