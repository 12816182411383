import React, { Suspense, lazy, useEffect } from 'react';
// import { connect } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import openSocket from 'socket.io-client';
import { LayoutSplashScreen, ContentRoute } from '../_ens/layout';
import { MyPage } from './pages/MyPage';
// import DashboardPage from './pages/DashboardPage';
import DashboardWrapper from './pages/DashboardWrapper';
import {
  getParentRoute,
  checkUserPermision,
  AllPermisions,
  getSettingData
} from './servics/CommonFunctions';
import ErrorPage from '../app/modules/Errors/ErrorPage';
import DashboardPage from './pages/Calendar';

const UserProfilepage = lazy(() => import('./modules/UserProfile/UserProfilePage'));

const ActiveUserListingPage = lazy(() => import('./modules/Admin/Users/ActiveUserList'));

const InactiveUserListingPage = lazy(() => import('./modules/Admin/Users/InActiveUserList'));

const InterpreterListingPage = lazy(() => import('./modules/Admin/Users/InterpreterList'));

const PendingListingPage = lazy(() => import('./modules/Admin/PendingReq/pendingReqs'));

const VideoCallPage = lazy(() => import('./pages/VideoCall/VideoCallPage'));

const CalculatingList = lazy(() => import('./modules/Admin/calculatingList/calculatingList'));

const UpdateChangeAppointment = lazy(() => import('./modules/Admin/updateChangeAppointment/index'));

const UpdateTimeAppointmentAdminForm = lazy(() =>
  import('./modules/Admin/updateChangeAppointment/form')
);

const RoomList = lazy(() => import('./pages/ChatMessage/RoomList'));
const AdminChatRoomList = lazy(() => import('./pages/AdminChatMessage/AdminChatRoomList'));

const UserInterpreterMessageView = lazy(() =>
  import('./pages/AdminChatMessage/UserInterpreterMessageView')
);

// export let socket;

function BasePage(props) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  let redirectUrl = getParentRoute();
  const isAdminAllowed = getSettingData();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={redirectUrl + 'dashboard'} />
        }
        <Redirect exact from={redirectUrl} to={redirectUrl + 'dashboard'} />

        {/* <ContentRoute path={redirectUrl + 'dashboard'} component={DashboardPage} /> */}
        <ContentRoute path={redirectUrl + 'dashboard'} component={DashboardWrapper} />
        <ContentRoute path={redirectUrl + 'calendar'} component={DashboardPage} />

        <ContentRoute path={redirectUrl + 'my-page'} component={MyPage} />

        <Route path={redirectUrl + 'user-profile'} component={UserProfilepage} />

        {checkUserPermision(AllPermisions.CHATMESSAGE) ? (
          <Route path={redirectUrl + 'chatMessage'} component={RoomList} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermisions.ADMINCHATMESSAGE) && isAdminAllowed ? (
          <Route path={redirectUrl + 'adminChatMessage'} component={AdminChatRoomList} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermisions.CHATMESSAGEWITHADMIN) ? (
          <Route
            path={redirectUrl + 'chatMessageWithAdmin'}
            component={UserInterpreterMessageView}
          />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermisions.CALCULATIONLIST) ? (
          <Route path={redirectUrl + 'calculationList'} component={CalculatingList} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermisions.VIDEOCALL) ? (
          <ContentRoute path={redirectUrl + 'call'} component={VideoCallPage} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}
        {checkUserPermision(AllPermisions.VIEWUSERLIST) ? (
          <Route path={redirectUrl + 'activeUsers'} component={ActiveUserListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermisions.VIEWUSERLIST) ? (
          <Route path={redirectUrl + 'inactiveUsers'} component={InactiveUserListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermisions.VIEWINTERPRATORLIST) ? (
          <Route path={redirectUrl + 'interpreterList'} component={InterpreterListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermisions.PENDINGAPPOINTMENT) ? (
          <Route path={redirectUrl + 'pendingreqs'} component={PendingListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermisions.UPDATEAPPOINTMENTTIME) ? (
          <Route
            path={redirectUrl + 'update-appointment-time'}
            component={UpdateChangeAppointment}
          />
        ) : null}
        {checkUserPermision(AllPermisions.UPDATEAPPOINTMENTTIME) ? (
          <Route
            path={redirectUrl + 'update-appointment-time-form/:id'}
            component={UpdateTimeAppointmentAdminForm}
          />
        ) : null}

        <Route path={redirectUrl + 'error'} component={ErrorPage} />

        <Redirect to={redirectUrl + 'error'} />
      </Switch>
    </Suspense>
  );
}

export default BasePage;
