/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import {
  getParentRoute,
  checkUserPermision,
  AllPermisions
} from '../../../../../app/servics/CommonFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

function AsideMenuList({ layoutProps, auth, settingData }) {
  const { value } = settingData;
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };
  let redirectUrl = getParentRoute();

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(redirectUrl + 'dashboard', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={redirectUrl + 'dashboard'}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Devices/Display1.svg')} />
            </span>
            <span className="menu-text">
              {' '}
              <FormattedMessage id="SIDEMENU.DASHBOARD" />
            </span>
          </NavLink>
        </li>

        {['User', 'Interpreter'].includes(auth.user.role) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'calendar', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'calendar'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Devices/Calendar.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.CALENDAR" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {/*end::1 Level*/}

        {/*}  <li
          className={`menu-item ${getMenuItemActive(redirectUrl+"user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={redirectUrl+"user-profile"}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/user.svg"
                )}
              />
            </span>
            <span className="menu-text"> <FormattedMessage id="SIDEMENU.PROFILE" /></span>
          </NavLink>
        </li>*/}

        {checkUserPermision(AllPermisions.VIEWUSERLIST) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'activeUsers', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'activeUsers'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.ACTIVEUSERS" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {checkUserPermision(AllPermisions.VIEWUSERLIST) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'inactiveUsers', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'inactiveUsers'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.INACTIVEUSERS" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {checkUserPermision(AllPermisions.VIEWINTERPRATORLIST) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'interpreterList', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'interpreterList'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.INTERPRETER" />
              </span>
            </NavLink>
          </li>
        ) : null}
        {checkUserPermision(AllPermisions.PENDINGAPPOINTMENT) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'pendingreqs', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'pendingreqs'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Warning-1-circle.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.PENDING_APPOINTMENTS" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {/* calculation list */}
        {checkUserPermision(AllPermisions.CALCULATIONLIST) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'calculationList', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'calculationList'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Devices/Calculator.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.CALCULATION_LIST" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {checkUserPermision(AllPermisions.UPDATEAPPOINTMENTTIME) ? (
          <li
            className={`menu-item ${getMenuItemActive(
              redirectUrl + 'update-appointment-time',
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'update-appointment-time'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.UPDATE.APPOINTMENT.TIME" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {checkUserPermision(AllPermisions.CHATMESSAGE) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'chatMessage', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'chatMessage'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat1.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.CHAT.MESSAGE" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {auth.user.role === 'Admin' &&
        value &&
        value['chat_admin'] &&
        value['chat_admin'].includes(auth.user.id) ? (
          <li
            className={`menu-item ${getMenuItemActive(redirectUrl + 'adminChatMessage', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'adminChatMessage'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat1.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.ADMIN.CHAT.MESSAGE" />
              </span>
            </NavLink>
          </li>
        ) : null}

        {checkUserPermision(AllPermisions.CHATMESSAGEWITHADMIN) ? (
          <li
            className={`menu-item ${getMenuItemActive(
              redirectUrl + 'chatMessageWithAdmin',
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={redirectUrl + 'chatMessageWithAdmin'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat1.svg')} />
              </span>
              <span className="menu-text">
                {' '}
                <FormattedMessage id="SIDEMENU.ADMIN.CHAT.MESSAGE" />
              </span>
            </NavLink>
          </li>
        ) : null}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  settingData: state.settingData
});

export default injectIntl(connect(mapStateToProps)(AsideMenuList));
