import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { register, getCityList } from '../_redux/authCrud';
import { bindActionCreators } from 'redux';
import * as common from '../../../../redux/commonReducer';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';

import { TextField, Paper, MenuItem } from '@material-ui/core';

import TermsAndConditionModel from './termAndConditionModel';

function Registration(props) {
  const [cityList, setCities] = useState(() => []);
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [proofPic, setproofpic] = useState({});
  const [backPic, setBackPic] = useState({});
  const [initialValues, setIntialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    cityId: '',
    zip_code: '',
    province: '',
    password: '',
    confirmPassword: '',
    acceptTerms: ''
  });
  useEffect(() => {
    getCityList().then(
      (res) => {
        setCities(res);
      },
      (err) => {
        disableLoading();
      }
    );
  }, []);
  const [proofPicToggle, setProofPicToggle] = useState(false);
  const [backPicToggle, setBackPicToggle] = useState(false);

  const [modalShow, setModelShow] = useState(false);

  const onModelHide = () => {
    setModelShow(false);
  };

  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    lastName: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.WRONGEMAIL'
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    cityId: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    phone: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    zip_code: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        6,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '6' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    province: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '2' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    address: Yup.string()
      .min(
        5,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '5' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        100,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '100' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    password: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '6' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({
            id: 'AUTH.VALIDATION.PASSWORD_NOT_MATCH'
          })
        )
      }),
    acceptTerms: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.ACCEPT_TERMS'
      })
    )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /*if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }*/

    return '';
  };

  const handleclick = (e) => {
    const index = cityList.findIndex((city) => city.id == e.target.value);
    formik.setValues({ ...formik.values, zip_code: cityList[index].zip });
  };

  function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={`suggestion1${suggestion.name}`}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  }

  function getSuggestions(value, { showEmpty = false } = {}) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0 && !showEmpty
      ? []
      : cityList.filter((suggestion) => {
          const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  function renderInput(inputProps) {
    const { InputProps, ref, ...other } = inputProps;

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {},
          ...InputProps
        }}
        {...other}
      />
    );
  }

  const onFileChange = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      setproofpic(reader.result);
      setProofPicToggle(true);
    };
    reader.onerror = (error) => {};
    //setproofpic(event.target.files[0])
  };

  // back file change function
  const onBackFileChange = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      setBackPic(reader.result);
      setBackPicToggle(true);
    };
    reader.onerror = (error) => {};
    //setproofpic(event.target.files[0])
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      enableLoading();
      //let formData = new FormData();
      if (values.acceptTerms === 'true') {
        if (!proofPicToggle) {
          disableLoading();
          setSubmitting(false);
          props.commonActions.showSnackBar(
            true,
            intl.formatMessage({
              id: 'ID_PROOF_VALIDATION'
            }),
            'error'
          );
        } else if (!backPicToggle) {
          disableLoading();
          setSubmitting(false);
          props.commonActions.showSnackBar(
            true,
            intl.formatMessage({
              id: 'ID_BACK_VALIDATION'
            }),
            'error'
          );
        } else {
          disableLoading();
          setSubmitting(false);
          values.role = 'User';
          values.id_proof = proofPic;
          values.id_back = backPic;
          values.acceptTerms = true;
          console.log(values);
          /* for(let key in values){
        formData.append(key,values[key])
      }
      formData.append("id_proof",proofPic)*/

          register(values)
            .then((res) => {
              disableLoading();
              setSubmitting(false);
              props.commonActions.showSnackBar(true, res.message);
              props.history.push('/auth/login');
            })
            .finally(() => {
              setSubmitting(false);
              /*setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );*/
              disableLoading();
            });
        }
      } else {
        disableLoading();
      }
    }
  });

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.DESC" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.FIRST_NAME'
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'firstName'
            )}`}
            name="firstName"
            {...formik.getFieldProps('firstName')}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.LAST_NAME'
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'lastName'
            )}`}
            name="lastName"
            {...formik.getFieldProps('lastName')}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.EMAIL'
            })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PHONE'
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'phone'
            )}`}
            name="phone"
            {...formik.getFieldProps('phone')}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.ADDRESS'
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'address'
            )}`}
            name="address"
            {...formik.getFieldProps('address')}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.address}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <Downshift
            id="downshift-simple"
            onChange={(selection) => {
              const index = cityList.findIndex((city) => city.name == selection);
              formik.setValues({
                ...formik.values,
                zip_code: cityList[index].zip,
                cityId: cityList[index].id
              });
            }}
          >
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem
            }) => {
              const { onBlur, onFocus, ...inputProps } = getInputProps({
                placeholder: intl.formatMessage({
                  id: 'AUTH.REGISTER.CITY'
                })
              });

              return (
                <div className="form-control form-control-solid h-auto py-5 px-6">
                  {renderInput({
                    fullWidth: true,
                    // label: "City",
                    // InputLabelProps: getLabelProps({ shrink: true }),
                    InputProps: { onBlur, onFocus },
                    inputProps
                  })}

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper square>
                        {getSuggestions(inputValue).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: suggestion.name
                            }),
                            highlightedIndex,
                            selectedItem
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              );
            }}
          </Downshift>

          {formik.touched.cityId && formik.errors.cityId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.cityId}</div>
            </div>
          ) : null}

          {/*} <select
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "cityId"
            )}`}
            placeholder={intl.formatMessage({
              id: "AUTH.REGISTER.SELECT_CITY",
            })}
            onChangeCapture={handleclick}
            name="cityId"
            {...formik.getFieldProps("cityId")}
          >
            <option value="" disabled>
            {intl.formatMessage({
              id: "AUTH.REGISTER.SELECT_CITY",
            })}
             
            </option>
            {cityList.map((city) => {

              return (
                <option  key={city.id} value={city.id}>
                  {city.name}
                </option>
              );
            })}
          </select>
          {formik.touched.cityId && formik.errors.cityId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.cityId}</div>
            </div>
          ) : null} */}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.ZIP_CODE'
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'zip_code'
            )}`}
            name="zip_code"
            {...formik.getFieldProps('zip_code')}
          />
          {formik.touched.zip_code && formik.errors.zip_code ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.zip_code}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PROVINCE'
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'province'
            )}`}
            name="province"
            {...formik.getFieldProps('province')}
          />
          {formik.touched.province && formik.errors.province ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.province}</div>
            </div>
          ) : null}
        </div>
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PASSWORD'
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.CPASSWORD'
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'confirmPassword'
            )}`}
            name="confirmPassword"
            {...formik.getFieldProps('confirmPassword')}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirmPassword}</div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        <div className="form-group fv-plugins-icon-container">
          <label
            className="btn btn-hover-text-primary btn-shadow"
            data-action="change"
            data-toggle="tooltip"
            style={{ width: '100%', textAlign: 'left' }}
            title=""
            data-original-title="Id Proof"
          >
            <span>
              {' '}
              <FormattedMessage id="AUTH.REGISTER.ID_PROOF" />{' '}
            </span>{' '}
            <br />
            <input
              type="file"
              className="mt-5"
              style={{ width: '200px' }}
              onChange={onFileChange}
              accept=".png, .jpg, .jpeg"
            />
          </label>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label
            className="btn btn-hover-text-primary btn-shadow"
            data-action="change"
            data-toggle="tooltip"
            style={{ width: '100%', textAlign: 'left' }}
            title=""
            data-original-title="Id Proof"
          >
            <span>
              {' '}
              <FormattedMessage id="AUTH.REGISTER.BACK_PROOF" />
            </span>{' '}
            <br />
            <input
              type="file"
              className="mt-5"
              style={{ width: '200px' }}
              onChange={onBackFileChange}
              accept=".png, .jpg, .jpeg"
            />
          </label>
        </div>

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox tick_in_front">
            <input
              // type="checkbox"
              type="radio"
              id="accept"
              value={'true'}
              name="acceptTerms"
              className="m-1"
              // {...formik.getFieldProps('acceptTerms')}
              onChange={(e) => formik.setFieldValue('acceptTerms', e.target.value)}
            />
            {/* <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
              {' '}
              <FormattedMessage id="AUTH.REGISTER.ACCEPT_TERMS" />
            </Link> */}
            <div className="mr-1">
              <a href="#" onClick={(e) => setModelShow(true)}>
                <FormattedMessage id="AUTH.REGISTER.ACCEPT_TERMS" />
              </a>
            </div>
            <span />
          </label>
          {/* {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null} */}
        </div>

        {/* end: Terms and Conditions */}
        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox tick_in_front">
            <input
              type="radio"
              name="acceptTerms"
              className="m-1"
              id="reject"
              value={'false'}
              // {...formik.getFieldProps('acceptTerms')}
              onChange={(e) => formik.setFieldValue('acceptTerms', e.target.value)}
            />
            {/* <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
              {' '}
              <FormattedMessage id="AUTH.REGISTER.REJECT_TERMS" />
            </Link> */}
            <div className="mr-1">
              <a href="#" onClick={(e) => setModelShow(true)}>
                <FormattedMessage id="AUTH.REGISTER.REJECT_TERMS" />
              </a>
            </div>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>

        {/* end: Terms and Conditions */}
        {/* !formik.values.acceptTerms || */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || !proofPic}
            className="btn btn-success font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
              <FormattedMessage id="SUBMIT" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button type="button" className="btn btn-danger font-weight-bold px-9 py-4 my-3 mx-4">
              <FormattedMessage id="CANCEL" />
            </button>
          </Link>
        </div>
      </form>
      {modalShow ? (
        <TermsAndConditionModel center={true} show={modalShow} onHide={onModelHide} />
      ) : (
        ''
      )}
    </div>
  );
}

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(null, mapDispatchToEvents)(Registration));
