import { Repository } from '../../servics/httpsServics';

const controller = '/accounts';
const availability = '/availability';
const appointments = '/appointments';

export function getUserListWithFilterPagination(role, data) {
  return Repository.get(controller + '/list_user' + '?role=' + role, { params: data });
}

export function getUserList(role) {
  return Repository.get(controller + '?role=' + role);
}

// for inactive user document get
export function getUserDocument(id) {
  return Repository.get(controller + '/get-document', {
    params: { id: id }
  });
}

export function deleteUser(id) {
  return Repository.delete(controller + '/' + id);
}

export function createUser(data) {
  return Repository.post(controller, data);
}

export function updateUser(data, id) {
  return Repository.put(controller + '/' + id, data);
}

export function getCalculationForInterpreter(data) {
  return Repository.get(controller + '/get-all-calculation', {
    params: data
  });
}

export function Availbility(data) {
  return Repository.post(availability, data);
}

export function getAvailbility() {
  return Repository.get(availability);
}

export function getUserAppointmentById(id) {
  return Repository.get(appointments + '/' + id);
}

export function deleteAppointment(id) {
  return Repository.delete(appointments + '/' + id);
}

export function getAllInterprator() {
  return Repository.get(availability + '/all');
}

export function getInterpratorAppointmentById(id) {
  return Repository.get(appointments + '/interepreter/' + id);
}

export function getInterpratorAvailbilityById(id) {
  return Repository.get(availability + '/' + id);
}

export function getUserAppointment() {
  return Repository.get(appointments);
}

export function MakeAppointment(data) {
  return Repository.post(appointments, data);
}

export function modifyAppointment(data) {
  return Repository.put(appointments, data);
}

export function modifyavailbility(data) {
  return Repository.put(availability, data);
}

export function deleteAvailbilities(data) {
  return Repository.put(availability + '/delete', data);
}

export function getpendingReqs() {
  return Repository.get(appointments + '/pending/appts');
}

export function getPendingList(data) {
  return Repository.get(appointments + '/pending/appts/list', { params: data });
}

export function assignAppointment(data) {
  return Repository.put(appointments + '/assign', data);
}

export function updateAppointment(data) {
  return Repository.put(appointments + '/reply', data);
}

export function getAdminSettings() {
  return Repository.get('/setting');
}

export function getAminChatIds() {
  return Repository.get('/setting/get-setting-admin-chat');
}

export function updateAdminSettings(data) {
  return Repository.put('/setting', data);
}

export function getUpdateTimeAppointmentList(data) {
  return Repository.get(appointments + '/get-update-time-appointments-admin', { params: data });
}

export function getUpdateChangeDataForAdmin(data) {
  return Repository.get(appointments + `/get-update-time-appointments-admin/${data}`);
}

export function updateAppointmentUpdateDataAdmin(data) {
  return Repository.post(appointments + '/update-admin-time-appointments', data);
}

export function allInterpreterMailForAppointment(data) {
  return Repository.get(appointments + `/all-interpreter-mail-appointment/${data}`);
}
