import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

function TermsAndConditionModel(props) {
  return (
    <Modal
      centered={props.center}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Termini & Condizioni</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>INFORMATIVA ai sensi degli artt. 13 e 14 del REGOLAMENTO UE 2016/679 (Regolamento Generale sulla protezione dei dati).</h4>

       <p>I dati da Lei forniti saranno trattati dall’ENS, titolare del trattamento, anche in forma automatizzata, nel rispetto delle adeguate misure di sicurezza ex art. 32 del Regolamento e non saranno sottoposti a diffusione, comunicazione, né ad altro trasferimento verso soggetti terzi diversi da ENS stesso, ad esclusione di quelli contenuti nell’informativa, nonché dei soggetti istitutzionali. I dati potranno essere conservati anche per fini statistici, attraverso l’anonimizzazione e pseudonimizzazione. Il titolare del trattamento dei dati è ENS Onlus, con sede in Roma – Via Gregorio VII n. 120. L’esercizio dei diritti potrà avvenire in forma scritta mediante l’invio di una email all’indirizzo privacy@ens.it. Responsabile del Trattamento è indicato nel presidente del Consiglio Regionale Lombardia.
       </p>
        <p>

      Io sottoscritto dichiaro di aver letto e compreso l’informativa sul trattamento e la protezione dei dati personali ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 pubblicata dall'ENS sul sito www.ens.it nell’apposita sezione privacy e che L’ENS tratterà i dati solo ai fini dell’erogazione del servizio “Comunicare Senza Barriere”, favorendo  il contatto iniziale con il singolo professionista, manlevando lo stesso ente da qualsivoglia responsabilità in merito alla prestazione resa ed al rapporto con il professionista stesso.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
          <FormattedMessage id="CANCEL" />
        </button>

        <> </>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(TermsAndConditionModel);
