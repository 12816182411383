/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, Switch, Redirect, Route } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_ens/_helpers';
import { ContentRoute } from '../../../../_ens/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import VerifyEmail from './VerifyEmail';
import ChangePassword from './ChangePassword';
import OtpVerify from './OtpVerify';
import '../../../../_ens/_assets/sass/pages/login/classic/login-1.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ContactModel from './ContactModel';
import SetInterpreterPassword from './SetInterpreterPassword';

function AuthPage(props) {
  const [modalShow, setModelShow] = useState(false);

  const onModelHide = () => {
    setModelShow(false);
  };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className=""
                  style={{ width: '200px' }}
                  src={toAbsoluteUrl('/media/logos/logo-letter-1.png')}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  <FormattedMessage id="AUTH.WELCOME" />
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  <FormattedMessage id="AUTH.SUBTITLE" />
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy;
                  <FormattedMessage id="AUTH.YEAR" />
                  <a
                    href="https://lombardia.ens.it/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white text-hover-primary"
                  >
                    <FormattedMessage id="FOOTER.ENS" />
                  </a>
                </div>
                <div className="d-flex">
                   <Link to={{ pathname: "https://www.ens.it/images/immagini/2019/05/Informativa_sulla_privacy.pdf" }} target="_blank"  className="text-white">
                    <FormattedMessage id="AUTH.PRIVACY" />
                  </Link>
                  <Link to={{ pathname: "https://www.ens.it/cookie-policy" }} target="_blank"  className="text-white ml-10">
                    <FormattedMessage id="AUTH.COOKIES" />
                  </Link>

                  {/* <Link to="#" > */}
                  {/* change for model open */}
                  <div className="text-white ml-10" onClick={() => setModelShow(true)}>
                    <FormattedMessage id="AUTH.CONTACT" />
                  </div>
                  {modalShow ? (
                    <ContactModel center={true} show={modalShow} onHide={onModelHide} />
                  ) : (
                    ''
                  )}
                  {/* </Link> */}
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {props.location.pathname != '/auth/registration' ? (
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 registration__wrapper">
                <span className="font-weight-bold text-dark-50">
                  <FormattedMessage id="AUTH.NOACCOUNT" />
                </span>
                <Link
                  to="/auth/registration"
                  className="font-weight-bold ml-2"
                  id="kt_login_signup"
                >
                  <FormattedMessage id="AUTH.SIGNUP!" />
                </Link>
              </div>
            ) : (
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">
                  <FormattedMessage id="AUTH.HAVEACCOUNT" />
                </span>
                <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">
                  <FormattedMessage id="AUTH.LOGIN!" />
                </Link>
              </div>
            )}

            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0 authentication__wrapper ">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/registration" component={Registration} />
                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />

                <ContentRoute path="/auth/verify-email" component={VerifyEmail} />

                <ContentRoute path="/auth/reset-password" component={ChangePassword} />
                <ContentRoute path="/auth/set-password" component={SetInterpreterPassword} />

                <ContentRoute path="/auth/verify-otp" component={OtpVerify} />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; <FormattedMessage id="AUTH.YEAR" />
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to={{ pathname: "https://www.ens.it/images/immagini/2019/05/Informativa_sulla_privacy.pdf" }} target="_blank"  className="text-dark-75 text-hover-primary">
                  <FormattedMessage id="AUTH.PRIVACY" />
                </Link>
                <Link to={{ pathname: "https://www.ens.it/cookie-policy" }} target="_blank"  className="text-dark-75 text-hover-primary ml-4">
                  <FormattedMessage id="AUTH.COOKIES" />
                </Link>
                {/* <Link to="/documentation" className="text-dark-75 text-hover-primary ml-4"> */}
                <div
                  className="text-dark-75 text-hover-primary ml-4"
                  onClick={() => setModelShow(true)}
                >
                  <FormattedMessage id="AUTH.CONTACT" />
                </div>
                {/* </Link> */}
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

export default injectIntl(withRouter(AuthPage));
