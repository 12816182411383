import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

function ContactModel(props) {
  useEffect(() => {}, [props]);

  return (
    <Modal
      centered={props.center}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Contatti</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="contact-modal">
          <div>
            <div>
              <div>
                <div><h3><span >EMAIL DEDICATA AL PROGETTO</span>
                </h3>
                  <p className="red-title"><a href={"mailto:csblombardia@ens.it"}>csblombardia@ens.it</a></p></div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div><h3 className="h3-style"><span>ENTE NAZIONALE PER LA PROTEZIONE E L’ASSISTENZA DEI SORDI ENS – ONLUS APS </span>
                </h3>
                  <p className="text"><span><em>Consiglio Regionale della Lombardia</em></span></p>
                  <p className="text"><span>Via Boscovich 38 – 20124 Milano</span></p>
                  <p className="text"><span>Telefono: <a href={"tel:+390220401836"} target="_blank" rel="noopener">02 20401836</a></span></p>
                  <p className="text"><span>Fax: <a href={"tel:+390220520239"} target="_blank" rel="noopener">02 20520239</a></span></p>
                  <p className="text"><span>Email: <a href={"mailto:lombardia@ens.it"} target="_blank" rel="noopener">lombardia@ens.it</a></span></p>
                  <p className="text"><span>Sito Web: <a href="https://lombardia.ens.it/" target="_blank" rel="noopener">https://lombardia.ens.it/</a></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
          <FormattedMessage id="CANCEL" />
        </button>

        <> </>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(ContactModel);
