import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  loader: '[Set loader] Action',
  snackbar: '[Show Snackbar] Action'
};

const initialAuthState = {
  loader: false,
  snackbar: {
    show: false,
    message: '',
    type: '',
    visibleTime: 0
  }
};

export const reducer = persistReducer(
  { storage, key: 'ens-common', whitelist: ['user', 'authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.loader: {
        return { ...state, loader: action.payload.value };
      }
      case actionTypes.snackbar: {
        const newState = { ...state };
        newState.snackbar = { ...newState.snackbar };
        newState.snackbar = action.payload;
        return { ...state, snackbar: newState.snackbar };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  showLoader: (value) => ({ type: actionTypes.loader, payload: { value } }),
  showSnackBar: (show = true, message = '', type = 'success', visibleTime = 2000) => ({
    type: actionTypes.snackbar,
    payload: { show, message, type, visibleTime }
  }),
  closeSnackBar: (show = false, message = '', type = 'success', visibleTime = 0) => ({
    type: actionTypes.snackbar,
    payload: { show, message, type, visibleTime }
  })
};
