/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {getpendingReqs,getUserList} from "../../../../../app/modules/Admin/AdminActions"
import { isAdmin,isInterpreter } from "../../../../../app/servics/CommonFunctions";
import AssignAppointment from "../../../../../app/modules/Admin/PendingReq/AssignAppointment"
import AcceptPopup from "../../../../../app/pages/Popup/UpdateAppointment"
import { FormattedMessage, injectIntl } from "react-intl";
import  moment from 'moment'


const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

function UserNotificationsDropdown(props) {
  var timer=0;
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  useEffect(()=>{
    startTimer()
    getPendingAppointments();
    if(isAdmin())
      getInterpratorList();

      return ()=>{
        clearInterval(timer)
      }
  },[])

  const [totoalPending,setTotalPending] = useState(null)
  const [pendingList,setPendingList] = useState([])
  const [interpreterList,setinterpreterList] = useState([])
  const [appointmetdata,setappointmetdata] = useState({})
const [modalShow,setModelShow] = useState(false)


const getInterpratorList = () =>{
  getUserList('Interpreter').then(res=>{
    setinterpreterList(res)
},err=>{

})
}

const startTimer = ()=> {
      timer = setInterval(getPendingAppointments, 120000);

}

  const getPendingAppointments = () =>{

    getpendingReqs().then(res=>{
      setTotalPending(res.length)
      setPendingList(res)
      },err=>{
      })
  }

  const onModelHide = () =>{
    setModelShow(false)
  }

const handleClick = (data) => {
  if (isAdmin()) {
    setappointmetdata({
      ...data.appointment,
      interpreterId: data.interpreterID != 0 ? data.interpreterID : ''
    });
  } else {
    setappointmetdata({
      ...data.appointment,
      id: data.id,
      status: data.status,
      ...data
    });
  }
  setModelShow(true);
};
return (
  <>
    {isInterpreter() && modalShow ? (
      <AcceptPopup
        modalShow={modalShow}
        apointmentModel={appointmetdata}
        onModelHide={onModelHide}
        updateCurrentUser={getPendingAppointments}
      ></AcceptPopup>
    ) : null}

    {modalShow && isAdmin() ? (
      <AssignAppointment
        modalShow={modalShow}
        apointmentModel={appointmetdata}
        interpreterList={interpreterList}
        updateCurrentUser={(() => setModelShow(false), getPendingAppointments)}
        onModelHide={onModelHide}
      ></AssignAppointment>
    ) : null}
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="user-notification-tooltip">
              <FormattedMessage id="NOTIFICATION.NOTIFICATION_HOVER" />
            </Tooltip>
          }
        >
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
              {totoalPending > 0 ? <>{totoalPending}</> : null}
            </span>
            <span className="pulse-ring"></span>
            <span className="pulse-ring" />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
        <form>
          {/** Head */}
          <div
            className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <h4 className="d-flex flex-center rounded-top">
              <span className="text-white">
                <FormattedMessage id="NOTIFICATION.TITLE" />
              </span>
              <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                {totoalPending} <FormattedMessage id="NOTIFICATION.NEW" />
              </span>
            </h4>

            <Tab.Container defaultActiveKey={key}>
              <Nav
                as="ul"
                className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                onSelect={(_key) => setKey(_key)}
              >
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                    eventKey="Alerts"
                    className={`nav-link show ${key === 'Alerts' ? 'active' : ''}`}
                  >
                    <FormattedMessage id="NOTIFICATION.ALERTS" />
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="tab-content">
                <Tab.Pane eventKey="Alerts" className="p-8">
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll pr-7 mr-n7"
                    style={{ maxHeight: '300px', position: 'relative' }}
                  >
                    {pendingList.length > 0 ? (
                      <>
                        {pendingList.map((item) => {
                          return (
                            <div
                              onClick={() => handleClick(item)}
                              key={item.id}
                              className="d-flex align-items-center mb-6"
                            >
                              <div className="symbol symbol-40 symbol-light-primary mr-5">
                                <span className="symbol-label">
                                  <SVG
                                    src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')}
                                    className="svg-icon-lg svg-icon-primary"
                                  ></SVG>
                                </span>
                              </div>
                              <div className="d-flex flex-column font-weight-bold">
                                <a
                                  href="#"
                                  className="text-dark text-hover-primary mb-1 font-size-lg"
                                >
                                  {item.appointment.account.firstName}{' '}
                                  {item.appointment.account.lastName} (
                                  {moment(item.appointment.date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                  )
                                </a>
                                <span className="text-muted">{item.appointment.place}</span>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <FormattedMessage id="NOTIFICATION.NONOTIFICATIONS" />
                    )}
                  </PerfectScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  </>
);
}

export default  injectIntl(UserNotificationsDropdown);
